<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title slot="start">{{ $t('registerGuest') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" class="ion-float-right"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-label class="ion-text-wrap" style="margin-left: 15px;">{{ $t('dgvdescription') }}</ion-label><br><br>
    <ion-card>
      <ion-card-header>
        <ion-item lines="none">
          <ion-label>{{ $t('clubInfo') }}</ion-label>
        </ion-item>
      </ion-card-header>
      <ion-card-content>
        <ion-grid>
          <ion-card>
            <ion-row>
              <ion-item lines="none">
                <ion-checkbox color="primary" v-model="useGolfCard"></ion-checkbox>
                <ion-label style="margin-left: 5px;" class="ion-text-wrap">{{ $t("dgvdescription1") }}</ion-label>
              </ion-item>                 
            </ion-row>
          </ion-card>
          <ion-card>
            <ion-row>
              <ion-list lines="none" class="ion-no-margin ion-no-padding">
                <ion-item v-if="useGolfCard">
                  <ion-label class="ion-text-wrap" position="stacked">{{ $t('dgvnumber') }}</ion-label><br>
                    <ion-row class="ion-no-margin ion-no-padding">
                      <ion-col size="auto">
                        <ion-select style="min-width: 100% !important;margin-left: -15px;" interface="popover" v-model="cardType" :value="cardType">
                          <ion-select-option value="DGV">DGV {{ $t('cardclub') }}</ion-select-option>          
                          <ion-select-option value="SwissGolf">Swiss Golf {{ $t('cardclub') }}</ion-select-option>     
                          <ion-select-option value="ÖGV">ÖGV {{ $t('cardclub') }}</ion-select-option>          
                        </ion-select>
                        <ion-input id="idInput" placeholder="9008100001" type="text" v-model="dgvnumber"></ion-input>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col>
                        <span style="font-size: 12px;">{{ $t('noBlanks') }}&nbsp;</span>
                        <span style="font-size: 12px;" v-if="cardType == 'DGV'">({{ $t('tendigits') }})</span>
                        <span style="font-size: 12px;" v-if="cardType == 'SwissGolf'">({{ $t('eightdigits') }})</span>
                        <span style="font-size: 12px;" v-if="cardType == 'ÖGV'">({{ $t('ninedigits') }})</span>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                    <ion-col size="auto">
                      <img v-if="cardType == 'DGV'" width="300" src="~@/assets/images/dgvcard.png">
                      <img v-if="cardType == 'SwissGolf'" width="300" src="~@/assets/images/swissgolfcard.png">
                      <img v-if="cardType == 'ÖGV'" width="300" src="~@/assets/images/oegvcard.png">
                    </ion-col>
                  </ion-row>
                </ion-item>
                <ion-item-divider v-if="!useGolfCard" color="">
                  <ion-label>
                    {{ $t('searchCountry') }}
                  </ion-label>
                </ion-item-divider>
                <ion-item v-if="selectedClub && !useGolfCard">
                  <ion-icon @click="removeSelectedClub()" color="danger" :md="trashOutline" :ios="trashOutline"></ion-icon>
                  <ion-label class="ion-text-wrap" color="success">{{ selectedClub.name }}</ion-label>
                </ion-item>
                <ion-item v-if="!useGolfCard" lines="full">
                  <ion-searchbar id="clubInput" :placeholder="$t('searchCountry')" v-model="searchText"  @ionBlur="setOnBlur()"  @ionClear="findClub('')" @input="findClub($event.target.value)"></ion-searchbar>
                </ion-item>
                <ion-item v-if="clubsFound && clubsFound.length > 0">
                  <ion-list>
                    <ion-list-header>
                      {{ $t('searchresult') }}
                    </ion-list-header>
                    <ion-item class="ion-text-wrap" v-for="(club, i) in clubsFound" :key="i" @click="addClub(club)">
                      <ion-label class="ion-text-wrap clickable" style="margin-left: 5px">
                        <h2>{{ club.name }}</h2>
                      </ion-label>
                    </ion-item>
                  </ion-list>
                </ion-item>
              </ion-list>
            </ion-row>
          </ion-card>
        </ion-grid>
      </ion-card-content>
    </ion-card>

    <ion-card>
      <ion-card-header>
        <ion-item lines="none">
          <ion-label>{{ $t('personInfo') }}</ion-label>
        </ion-item>
      </ion-card-header>
      <ion-card-content>
        <ion-list lines="full" class="ion-no-margin ion-no-padding">
          <ion-item>
            <ion-label position="stacked">{{ $t('gender') }}</ion-label>
            <ion-select interface="popover" v-model="gender" :value="gender">
              <ion-select-option value="female">{{ $t('female') }}</ion-select-option>          
              <ion-select-option value="male">{{ $t('male') }}</ion-select-option>          
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">{{ $t('title') }}</ion-label>
            <ion-select interface="popover" v-model="title" :value="title">
              <ion-select-option value=""></ion-select-option>          
              <ion-select-option value="Dr.">Dr.</ion-select-option>          
              <ion-select-option value="Prof.">Prof.</ion-select-option>          
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">{{ $t('firstName') }}</ion-label>
            <ion-input placeholder="Max" type="text" v-model="firstName"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">{{ $t('lastName') }}</ion-label>
            <ion-input placeholder="Mustermann" type="text" v-model="lastName"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked"  @click="showDatePicker = !showDatePicker">{{ $t('birthdate') }}</ion-label>
            <span v-if="!isPlatform('capacitor')" style="font-size: 16px;" position="stacked" @click="showDatePicker = !showDatePicker"> {{ birthdateFormated }}</span>
            <DatePicker v-if="!isPlatform('capacitor')" v-show="showDatePicker == true" v-model="birthdate" />
            <ion-datetime v-if="isPlatform('capacitor')" v-model="birthdate" display-format="DD.MM.YYYY" required></ion-datetime>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">{{ $t('email') }}</ion-label>
            <ion-input placeholder="test@test.com" type="email" v-model="email"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">{{ $t('hcp') }}</ion-label>
            <ion-input type="number" :value="hcp" v-model="hcp"></ion-input>
          </ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>

    <ion-card>
      <ion-card-header>
        <ion-item lines="none">
          <ion-button v-if="isPlatform('mobile') && isOpenDisplayOption" fill="clear" @click="isOpenDisplayOption=!isOpenDisplayOption">
            <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'" color="primary" :md="chevronDownOutline" :ios="chevronDownOutline"></ion-icon>
          </ion-button>
          <ion-button v-if="isPlatform('mobile') && !isOpenDisplayOption" fill="clear" @click="isOpenDisplayOption=!isOpenDisplayOption">
            <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'" color="primary" :md="chevronUpOutline" :ios="chevronUpOutline"></ion-icon>
          </ion-button>
          <ion-label>{{ $t('displayOptions') }}</ion-label>
        </ion-item>
      </ion-card-header>
      <ion-card-content v-if="isOpenDisplayOption">
        <ion-list lines="full" class="ion-no-margin">
          <ion-item>
              <ion-checkbox color="primary" v-model="showName"></ion-checkbox>
              <ion-label style="margin-left: 5px;" class="ion-text-wrap">{{ $t("showName") }}</ion-label>
          </ion-item>
          <ion-item>
              <ion-checkbox color="primary" v-model="showHCP"></ion-checkbox>
              <ion-label style="margin-left: 5px;" class="ion-text-wrap">{{ $t("showHCP") }}</ion-label>
          </ion-item>
          <ion-item>
              <ion-checkbox color="primary" v-model="showGender"></ion-checkbox>
              <ion-label style="margin-left: 5px;" class="ion-text-wrap">{{ $t("showGender") }}</ion-label>
          </ion-item>
          <ion-item>
            <ion-checkbox color="primary" v-model="allow4USearch"></ion-checkbox>
              <ion-label style="margin-left: 5px;" class="ion-text-wrap">{{ $t("allow4USearch") }}</ion-label>
            </ion-item>                 
            <ion-item>
              <ion-checkbox color="primary" v-model="privacyInfoscreen"></ion-checkbox>
              <ion-label style="margin-left: 5px;" class="ion-text-wrap">{{ $t("privacyInfoscreen") }}</ion-label>
            </ion-item>       
        </ion-list>
      </ion-card-content>
    </ion-card>

    <ion-card>
      <ion-card-content>
        <ion-list lines="full" class="ion-no-margin">
          <ion-item v-if="clubInfo && clubInfo.clubInfo && (clubInfo.clubInfo.maskedNumber == '494436' || clubInfo.clubInfo.maskedNumber == '499008')">
              <ion-checkbox color="primary" v-model="okGolfChain"></ion-checkbox>
              <ion-label style="margin-left: 5px;" class="ion-text-wrap">{{ $t("golfChain") }}</ion-label>
          </ion-item>
          <ion-item>
              <ion-checkbox color="primary" v-model="okAgb"></ion-checkbox>
              <ion-label style="margin-left: 5px;" class="ion-text-wrap">{{ $t("acceptAgb") }}</ion-label>
              <ion-button @click="openAgb()" style="margin-left: 10px;" class="ion-no-margin ion-no-padding" fill="clear">
                <ion-icon style="zoom: 1.4; vertical-align: middle;" color="primary" :md="openOutline" :ios="openOutline"></ion-icon>
              </ion-button>
          </ion-item>
          <ion-item>
              <ion-checkbox color="primary" v-model="okData"></ion-checkbox>
              <ion-label style="margin-left: 5px;" class="ion-text-wrap">{{ $t("acceptDSGVO") }}</ion-label>
              <ion-button @click="openData()" style="margin-left: 10px;" class="ion-no-margin ion-no-padding" fill="clear">
                <ion-icon style="zoom: 1.4; vertical-align: middle;" color="primary" :md="openOutline" :ios="openOutline"></ion-icon>
              </ion-button>
          </ion-item>
          <ion-label v-if="errorText != ''" class="ion-text-wrap" color="danger"><strong>{{ errorText }}</strong></ion-label>
          <ion-button :disabled="isDisabled" @click="registerUser()" expand="block" color="success">{{ $t('register') }}</ion-button>
        </ion-list>
      </ion-card-content>
    </ion-card>

  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonDatetime, IonItem, IonList, IonIcon, IonGrid, IonCol, IonRow, modalController, isPlatform,
        IonItemDivider, IonSearchbar, IonListHeader, IonSelectOption, IonSelect, IonCheckbox } from '@ionic/vue';
import { defineComponent, ref, watch, onMounted } from 'vue';
import { closeOutline, closeCircleOutline, trashOutline, openOutline, chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

import { useMutation, useResult, useQuery } from '@vue/apollo-composable'
import registerUserMutationFile from '../graphql/registerUser.mutation.gql'
import findClubsQueryFile from '../graphql/findClubs.query.gql'
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import agbDialog from './dialogs/AGBDialog'
import dataDialog from './dialogs/DataLegalDialog'
import { getClubInfo } from '@/composition'

import * as dayjs from 'dayjs';
import 'v-calendar/dist/style.css';

const isEmail = (value) => new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)


export default defineComponent({
  name: 'RegisterDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonDatetime, IonItem, IonCheckbox,
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonSearchbar, IonListHeader, IonSelectOption, IonSelect },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    }
  },
  setup() {
    const birthdate = ref('1970-06-15')
    const selectedClub = ref()
    const searchText = ref('')
    const errorText = ref('')
    const gender = ref('female')
    const title = ref('')
    const addInfo = ref('')
    const cardType = ref('DGV')
    const hcp = ref(0)
    const linkSent = ref(false)
    const useIDCardNumber = ref(false)
    const okAgb = ref(false)
    const okGolfChain = ref(false)
    const okData = ref(false)
    const showDatePicker = ref(false)
    const clubInfo = ref(getClubInfo())
    const useGolfCard = ref(true)
    const dgvnumber = ref('')
    const idInput = ref()
    const clubInput = ref()
    const showName = ref(true)
    const showHCP = ref(true)
    const showGender = ref(true)
    const allow4USearch = ref(true)
    const privacyInfoscreen = ref(true)
    const isOpenDisplayOption = ref(isPlatform('mobile') ? false : true)

    const { refetch: findClubsRefetch, result: findClubsResult, query: findClubsQuery } = useQuery(findClubsQueryFile, () => ({ searchTerm: '' }))

    const clubsFound = useResult(findClubsResult, null, data => data.findClubs.clubs)

    const { mutate: registerUserMutation } = useMutation(registerUserMutationFile, { fetchPolicy: 'no-cache' });

    onMounted(() => {
      setTimeout(() => { var currentSlot = document.getElementById('idInput'); currentSlot.setFocus() }, 500)
    });

    watch(useGolfCard, (newVal, oldVal) => {
      if (!newVal)  {
        dgvnumber.value = ''
        setTimeout(() => { var currentSlot = document.getElementById('clubInput'); currentSlot.setFocus() }, 500)
      }
      else {
        selectedClub.value = null
        setTimeout(() => { var currentSlot = document.getElementById('idInput'); currentSlot.setFocus() }, 500)
      }
    })

    return {
      closeOutline, birthdate, findClubsResult, clubsFound, errorText, gender, title, hcp, isPlatform,
      closeCircleOutline, selectedClub, searchText, trashOutline, addInfo, linkSent, cardType,
      registerUserMutation, findClubsRefetch, v$: useVuelidate(), useIDCardNumber, okAgb, okData, openOutline, showDatePicker, clubInfo, okGolfChain, useGolfCard, dgvnumber, idInput, clubInput, showName, showHCP, showGender, allow4USearch, privacyInfoscreen, chevronDownOutline, chevronUpOutline, isOpenDisplayOption
    }
  },
  computed: {
    UserData : function(){ return this.$store.getters.UserData},
    birthdateFormated() { return dayjs(this.birthdate).format('DD.MM.YYYY') },
    isDisabled() {
      let numLength = 10
      if (this.cardType == 'SwissGolf')
        numLength = 8
      else if (this.cardType == 'ÖGV')
        numLength = 9
      if (this.linkSent)
        return true
      if (this.dgvnumber.length >= numLength && isEmail(this.email) && !this.v$.firstName.$invalid && !this.v$.lastName.$invalid && this.okAgb && this.okData) {
        this.useIDCardNumber = true
        return false
      }
      if (this.dgvnumber.length >= numLength)
        this.useIDCardNumber = true
      else
        this.useIDCardNumber = false
      return this.v$.$invalid || !this.okAgb || !this.okData || (!this.selectedClub && (this.dgvnumber == '' || this.dgvnumber.length < numLength))
    },
  },
  validations () {
    return {
      firstName: { required }, lastName: { required }, email: { required, isEmail }
    }
  },
  methods: {
    async openAgb() {
      const modal = await modalController
        .create({
          component: agbDialog,
        })
      return modal.present();
    },
    async openData() {
      const modal = await modalController
        .create({
          component: dataDialog,
        })
      return modal.present();
    },
    removeSelectedClub()  {
      this.selectedClub = null
    },
    setOnBlur() {
      setTimeout(() => { this.searchText = '' }, 500)
      
    },
    async addClub(club) {
      this.selectedClub = club
      this.findClubsRefetch({ searchTerm: ''})
    },
    async findClub(searchTerm) {
      if (searchTerm.length > 3) {
        this.findClubsRefetch({ searchTerm: searchTerm})
      }
      else {
        this.findClubsRefetch({ searchTerm: ''})
      }
    },
    closeDialog() {
      return modalController.dismiss();
    },
    registerUser() {
      this.registerUserMutation({    
        golfChain: this.okGolfChain,     
        email: this.email,
        password: this.password,
        firstName: this.firstName,
        lastName: this.lastName,
        birthdate: dayjs(this.birthdate).format('YYYY-MM-DD'),
        gender: this.gender,
        title: this.title,
        hcp: this.hcp == null || this.hcp == '' ? 59 : parseFloat(this.hcp),
        dgvnumber: this.dgvnumber,
        cardtype: this.cardType,
        club: this.selectedClub ? parseInt(this.selectedClub._id) : 0,
        language: this.$store.getters.language,
        showName: this.showName,
        showHCP: this.showHCP,
        showGender: this.showGender,
        allow4USearch: this.allow4USearch,
        privacyInfoscreen: this.privacyInfoscreen
        })
        .then(res => {
          if (res && res.data && res.data.registerUser && !res.data.registerUser.success) {
            this.addInfo = res.data.registerUser.addInfo
            this.errorText = this.$t(res.data.registerUser.errorCode).replace('#', res.data.registerUser.addInfo)
          }
          if (res && res.data && res.data.registerUser && res.data.registerUser.success) {
            this.linkSent = true
            this.errorText = this.$t('activationLinkSent').replace('#', res.data.registerUser.addInfo)
          }
        })
        .catch(err => {
            this.errorText = this.$t('b4d05e58') + ': ' + err
        })
    }

  }
});
</script>

<style scoped>

.clickable {
    cursor: pointer;
}
</style>