module.exports = {
  "translation": {
    "clubinone": "CLUBINONE 4U",
    "home": "Il mio club",
    "pros": "Pro",
    "persons": "Persone",
    "competitions": "Tornei",
    "competition": "torneo",
    "competitionUC": "Torneo",
    "bookings": "Prenotazioni",
    "statistics": "Statistiche",
    "settings": "Impostazioni",
    "profile": "Profilo",
    "login": "Accesso",
    "logout": "Logout",
    "organizations-list": "Le mie organizzazioni",
    "companies": "Clubs",
    "company": "Club",
    "searchcompanies": "Cerca club",
    "searchresult": "Risultato della ricerca",
    "history": "Recente",
    "register": "Registra",
    "save": "Salva",
    "userData": "Dati utente",
    "changePassword": "Cambiare la password",
    "changeEmail": "Cambiare l'e-mail",
    "language": "Lingua",
    "firstName": "Nome",
    "lastName": "Nome e cognome",
    "birthdate": "Compleanno",
    "password": "Password",
    "email": "Email",
    "profilePicture": "Foto del profilo",
    "takePicture": "Seleziona l'immagine",
    "upload": "Salva immagine",
    "passwordReset": "Inviare e-mail di reimpostazione della password",
    "events": "Eventi",
    "event": "evento",
    "eventUC": "Evento",
    "loading": "Caricamento dei dati ...",
    "newcompanies": "Diventa un ospite",
    "erroLogin": "Effettua il login per creare un club!",
    "newCategory": "Nuova categoria",
    "description": "Descrizione",
    "changeSuccessful": "Cambiamento di successo",
    "changeNotSuccessful": "Errore durante il cambiamento",
    "deleteSuccessful": "Cancellare il successo",
    "deleteNotSuccessful": "Errore durante la cancellazione",
    "addSuccessful": "Aggiungere il successo",
    "addNotSuccessful": "Errore durante l'aggiunta",
    "notSuccessful": "Si è verificato un errore",
    "name": "Nome",
    "shortName": "Nome breve",
    "priority": "Priorità",
    "delete": "Cancellare",
    "cancel": "Cancella",
    "ok": "OK",
    "bookingDuration": "Durata della prenotazione",
    "showAvailableSlots": "Mostra gli slot disponibili",
    "showBookedSlots": "Mostra gli slot prenotati",
    "withSlider": "Mostra il cursore",
    "intervallBetweenBookings": "Tempo tra due prenotazioni",
    "maxBookingsInFuture": "Prenotazioni massime",
    "maxBookingsPerDay": "Max. prenotazioni al giorno",
    "maxBookingsPerWeek": "Max. prenotazioni per settimana",
    "maxPersonsBookable": "Numero massimo di slot prenotabili",
    "minPersonsBookable": "Numero minimo di slot prenotabili",
    "newBookingType": "Nuovo tipo di prenotazione",
    "from": "Periodo da",
    "to": "Periodo a",
    "cart": "Carrello",
    "teetimes": "Teetimes",
    "pro": "Pro",
    "courses": "Corsi",
    "course": "Corso",
    "ranges": "Gamma",
    "available": "",
    "sperrungnoshow": "Bloccato",
    "showAvatar": "Mostra l'avatar nei calendari delle prenotazioni",
    "withDog": "Con cane",
    "with9Hole": "Piani per giocare 9 buche",
    "withPlayWithMe": "Felice di prenotare con me",
    "member": "Membro",
    "memberFemale": "Membro femminile",
    "memberMale": "Membro maschio",
    "guest": "Ospite",
    "guestFemale": "Donna ospite",
    "guestMale": "Ospite maschio",
    "displayOptions": "Opzioni di visualizzazione",
    "showName": "Mostra il nome",
    "showHCP": "Mostra l'handicap",
    "showGender": "Mostra il genere",
    "all": "Tutti",
    "min1": "Min. 1 posto libero",
    "min2": "Min. 2 posti liberi",
    "min3": "Min. 3 posti liberi",
    "min4": "Min. 4 posti liberi",
    "hcp": "Handicap",
    "syncHCP": "Sincronizzare l'handicap",
    "hcpSyncOK": "Sincronizzazione dell'handicap riuscita",
    "hcpErrorSync": "Errore di sincronizzazione dell'handicap",
    "bookingTeetimeHelp": "Aiuto prenotazione teetime",
    "myBooking": "La mia prenotazione",
    "monday": "Lun",
    "tuesday": "Mar",
    "wednesday": "Mer",
    "thursday": "Gio",
    "friday": "Ven",
    "saturday": "Sab",
    "sunday": "Dom",
    "makeBooking": "Prenota il teetime",
    "makeBookingAndPay": "Prenota e paga teetime",
    "makeProBooking": "Prenota una lezione professionale",
    "makeAndPayProBooking": "Prenota e paga la lezione pro",
    "makeRangeBooking": "Prenota lo slot della gamma",
    "makeAndPayRangeBooking": "Prenota e paga lo slot della gamma",
    "maxDays": "Giorni massimi per la prenotazione",
    "timeSwitch": "Tempo aggiungi un giorno in più",
    "allowAlwaysBookingHours": "Orari sempre prenotabili",
    "courseConfigs": "Configurazione del corso",
    "unlimited": "Nessuna restrizione",
    "myCourseData": "I miei dati",
    "myFutureBookings": "Totale delle prenotazioni",
    "myBookingToday": "Prenotazioni data selezionata",
    "myBookingWeek": "Prenotazioni questa settimana",
    "bookingCourseHelp": "Aiuto Configurazione del corso",
    "bookingCourseHelp1": "Queste configurazioni sono state depositate dal club di golf.",
    "openFriends": "Configurare gli amici",
    "editBooking": "Modifica della prenotazione",
    "@me": "Io",
    "player": "Giocatore",
    "username": "Numero di carta d'identità o soprannome",
    "announcement": "Annuncio",
    "participants": "Partecipanti",
    "of": "di",
    "round": "Rotonda",
    "guests": "Ospiti",
    "webAvailable": "Disponibile in web",
    "PF0": "Single",
    "PF1": "Aggregat Foursome",
    "PF2": "Foursome",
    "PF3": "Foursome best drive",
    "PF4": "Chapman Foursome",
    "PF5": "Ryesome Foursome",
    "PF6": "Fourball",
    "PF7": "Fourball Bestball",
    "PF8": "Scramble",
    "PF9": "Twosome Scramble",
    "PF10": "Texas Scramble",
    "PF11": "Florida Scramble",
    "PF12": "RPR",
    "SM0": "Strokeplay",
    "SM1": "Stableford",
    "SM2": "Against Par",
    "SM3": "Matchplay",
    "SM4": "Strokeplay and Stableford",
    "SM5": "Mixed",
    "SM6": "Flag",
    "SM7": "Free",
    "SM8": "Strokeplay max score",
    "W0": "18 holes",
    "W1": "9 holes",
    "HCPREL0": "Non rilevante per l'HCPI",
    "HCPREL1": "HCPI™-Rilevante",
    "HCPREL2": "Tiger Rabbit",
    "regPeriod": "Periodo di registrazione",
    "regPeriodStart": "Apertura delle iscrizioni",
    "regPeriodEnd": "Data di chiusura delle iscrizioni",
    "hcpi": "HCPI™",
    "toStr": "a",
    "timerRunning": "Teetime sta ancora aspettando...",
    "timerStopped": "Il tuo teetime è ora disponibile anche per altri clienti.",
    "teetimeLocked": "Teetime è bloccato",
    "tournamentRegisterError1": "Non connesso",
    "tournamentRegisterError2": "Genere non corrispondente",
    "tournamentRegisterError3": "Handicap non corrispondente",
    "tournamentRegisterError4": "Periodo di registrazione non corrispondente",
    "tournamentRegisterError5": "L'elenco degli iscritti è pieno",
    "tournamentRegisterError6": "Gruppo d'età non corrispondente",
    "tournamentRegisterError7": "Registrato in #",
    "tournamentUnRegisterError1": "Non connesso",
    "tournamentUnRegisterError2": "Disiscrizione non consentita",
    "tournamentUnRegisterError3": "Data di disiscrizione superata",
    "tournamentUnRegisterError4": "Non registrato in #",
    "tournamentUnRegisterError5": "Disiscrizione non consentita perché pagata online",
    "helpCoursesTime": "Data del corso, ora di inizio e fine",
    "helpCoursesEnter": "Registrati al corso",
    "helpCoursesUnregister": "Disiscriversi dal corso",
    "helpCoursesSeries": "Serie di corsi",
    "helpCourses": "Corsi di aiuto",
    "helpTournamentTime": "Data del torneo, ora di inizio e fine",
    "helpTournamentDown": "Aprire i dettagli",
    "helpTournamentUp": "Chiudi i dettagli",
    "helpTournamentEnter": "Registra",
    "helpTournamentEnterWaitlist": "Registrati per la lista d'attesa",
    "helpTournamentUnregister": "Disdire il registro",
    "helpTournamentGenderFemale": "Torneo consentito alle femmine",
    "helpTournamentGenderMale": "Torneo consentito ai maschi",
    "helpTournamentML": "Elenco delle iscrizioni",
    "helpTournamentSL": "Lista di partenza",
    "helpTournamentEL": "Elenco dei risultati",
    "helpTournamentAnnouncment": "Annuncio",
    "helpTournament": "Aiuto Tornei",
    "helpEventTime": "Data dell'evento, ora di inizio e fine",
    "helpEventDown": "Aprire i dettagli",
    "helpEventUp": "Chiudi i dettagli",
    "helpEventEnter": "Registrati per l'evento",
    "helpEventEnterWaitlist": "Registrati per la lista d'attesa dell'evento",
    "helpEventUnregister": "Disiscriversi dall'evento",
    "helpEventGenderFemale": "Evento consentito alle femmine",
    "helpEventGenderMale": "Evento consentito ai maschi",
    "helpEventAnnouncment": "Descrizione",
    "helpEvent": "Aiuto Eventi",
    "helpRoundSL": "Lista di partenza",
    "unregister": "Firma fuori da",
    "unregisterSuccessful": "Firmato con successo",
    "unregisterNotSuccessful": "Errore durante la registrazione",
    "registerSuccessful": "Registrato con successo",
    "registerNotSuccessful": "Errore durante il registro",
    "none": "Nessuna selezione",
    "early": "All'inizio",
    "late": "Tardo",
    "makeTournamentRegistration": "Registra",
    "makeTournamentRegistrationAndPay": "Registrarsi e pagare",
    "makeTournamentUnRegistration": "Disdire il registro",
    "comment": "Commento",
    "registerTournament": "Registra",
    "unregisterTournament": "Disdire il registro",
    "addAdditionalPlayers": "Altri giocatori della squadra",
    "addAdditionalFlightPlayers": "Altri giocatori",
    "searchPlayer": "Ricerca giocatori",
    "friends": "Amici",
    "friendGroups": "Gruppi di amici",
    "friendsSave": "Salvare gli amici",
    "addAdditionalFriends": "Aggiungere amici",
    "friendsNotConfirmed": "Richiesta di amicizia",
    "friendsDeclined": "Richieste di amicizia rifiutate",
    "noTournaments": "Nessun torneo trovato",
    "noEvents": "Nessun evento trovato",
    "noTeetimeCourseFound": "Nessun calendario di prenotazione teetime trovato",
    "hole": "Foro",
    "given": "dato",
    "noPros": "Nessun professionista trovato",
    "noRanges": "Nessun range slot trovato",
    "nextFreeSlot": "Il prossimo slot gratuito di oggi",
    "booked": "prenotato",
    "precedingHours": "Ore precedenti per prenotare uno slot",
    "precedingStornoMinutes": "Minuti fino a quando una prenotazione può essere cancellata",
    "proHelp": "Aiuto Pro",
    "proConfigs": "Pro Configs",
    "rangeHelp": "Gamma di aiuto",
    "rangeConfigs": "Configurazione della gamma",
    "noCourses": "Nessun corso trovato",
    "coursename": "Campo da golf",
    "placename": "Posto",
    "priceinfo": "Informazioni sul prezzo",
    "onlinecomments": "Commento",
    "livescorings": "Punteggio digitale",
    "noLivescorings": "Nessun torneo trovato oggi",
    "noEmail": "Non c'è un indirizzo e-mail disponibile. Contatta il club per far aggiungere il tuo indirizzo email.",
    "noEmailSMSLogIn": "Non esiste un indirizzo e-mail. È possibile impostare la propria e-mail tramite il numero di cellulare o contattare il club per far memorizzare il proprio indirizzo e-mail",
    "notActivated": "Il tuo account non è attivato. Per favore, clicca su invia il link di attivazione per verificare la tua email. L'indirizzo e-mail non è più attuale? Poi informate il golf club dell'indirizzo e-mail attuale e ripetete il processo.",
    "sendActivationLink": "Invia il link di attivazione",
    "emailSendOK": "Email inviata con successo",
    "emailSendError": "Errore nell'invio dell'e-mail",
    "verify": "Attivare l'account",
    "verifyEmailOK": "L'account è stato attivato con successo.",
    "verifyEmailError": "Errore nell'attivazione dell'account",
    "reset": "Imposta nuova password",
    "resetPassword": "Cambiare la password",
    "passwordConfirm": "Conferma la password",
    "password8Chars": "Almeno 8 caratteri",
    "password1UpperCase": "Almeno 1 lettera maiuscola",
    "password1Number": "Almeno un numero",
    "password1SpecialChar": "Almeno un carattere speciale (- + _ ! @ # $ % ^ & *)",
    "password1PasswordSame": "Le password corrispondono a",
    "resetPasswordOK": "Password cambiata con successo",
    "resetPasswordError": "Errore nel cambiare la password",
    "passwordRules": "Mente. 8 caratteri, una maiuscola, un numero e un carattere speciale (-, +, _, !, @, #, $, %, ^, &, *, .)",
    "bookingDeleted": "Prenotazione cancellata con successo",
    "bookingAdded": "Prenotazione aggiunta con successo",
    "dgvnumber": "Numero ID a 10 cifre (esclusivamente per i membri delle associazioni di golf)",
    "dgvdescription": "Registrati rapidamente e facilmente ora!",
    "dgvdescription1": "Membro di una delle tre associazioni di golf elencate?",
    "dgvdescription2": "Poi seleziona la rispettiva carta e inserisci il numero ID a 10 cifre e i dati personali",
    "searchClubs": "Cerca il paese/club se non sei membro di un club tedesco, svizzero o austriaco",
    "searchCountry": "Cerca paese/club",
    "gender": "Genere",
    "male": "Maschio",
    "female": "Femminile",
    "title": "Titolo",
    "activationLinkSent": "Un link di attivazione è stato inviato via e-mail. Controlla la tua casella di posta e attiva il tuo account. L'e-mail potrebbe anche essere finita nella cartella dello spam. Il tuo nome utente per il login è: #",
    "cardclub": "Scheda",
    "marker": "Marcatore",
    "clear": "Chiaro",
    "confirm": "Confermare",
    "signaturePlayer": "Giocatore con firma",
    "signatureMarker": "Marcatore di firma",
    "read": "Leggi",
    "gentleman": "Uomini",
    "ladies": "Donne",
    "missingScores": "Mancano i punteggi. La conferma della firma non è possibile!",
    "hometeetimes": "Prenota teetime",
    "homefriends": "I miei amici",
    "homecard": "Le mie carte RFID",
    "homepros": "Prenota Pro",
    "homecourses": "Prenota un corso",
    "homeranges": "Prenota slot gamma",
    "homecompetitions": "Calendario tornei",
    "homelivescorings": "Scorecard in app",
    "homeevents": "Calendario eventi",
    "homeMemberdata": "I miei dati",
    "homePayment": "Pagamento in app",
    "homeMember": "Sei già un membro o un ospite? Poi usa tutti i servizi online direttamente dopo il login.",
    "homeEmail": "Indirizzo e-mail memorizzato nel club?",
    "homeNewCustomer": "Registrati ora come nuovo ospite e usa tutti i servizi online.",
    "myclub": "I miei dati",
    "personInfo": "Informazioni personali",
    "clubInfo": "Informazioni sul club",
    "addressInfo": "Informazioni sull'indirizzo",
    "bankInfo": "Informazioni sulla banca",
    "authorizePayments": "Autorizzare i pagamenti",
    "revokePayments": "Revocare l'autorizzazione",
    "paypalPayment": "Pagamento Paypal",
    "paypalAuthorized": "Hai autorizzato con successo il pagamento PayPal",
    "creditCardPayment": "Pagamento con carta di credito",
    "cardholderName": "Nome del titolare della carta",
    "creditCardNumber": "Numero di carta di credito",
    "expiration": "Scadenza",
    "accept": "Aggiungi carta di credito",
    "creditCardAuthorized": "Hai autorizzato con successo il pagamento con carta di credito",
    "creditCardAuthorizedFailed": "Autorizzazione fallita",
    "noPaymentMethod": "Non c'è ancora un metodo di pagamento memorizzato. Basta salvare nel profilo PayPal o una carta di credito.",
    "paymentMethodDeleted": "Il metodo di pagamento è stato cancellato",
    "paymentMethodCreated": "Il metodo di pagamento è stato aggiunto",
    "startdate": "Data di inizio",
    "contractstart": "Data di inizio del contratto",
    "contractend": "Data di fine contratto",
    "enddate": "Data di fine",
    "membership": "Iscrizione",
    "contract": "Contratto",
    "homeclubnum": "Numero del club di casa",
    "street": "Via",
    "city": "Città",
    "telephone": "Telefono",
    "mobile": "Mobile",
    "newsletter": "Newsletter",
    "allowNewsletter": "Sottoscritto",
    "notAllowNewsletter": "Non iscritto",
    "emailbill": "Inviare la fattura via e-mail",
    "allowBillEmail": "Sottoscritto",
    "notAllowBillEmail": "Non iscritto",
    "accountowner": "Proprietario del conto",
    "bank": "Banca",
    "iban": "IBAN",
    "bic": "BIC",
    "debOk": "Ritiro a debito",
    "debitsOk": "Consentito",
    "debitsNotOk": "Non consentito",
    "refNumber": "Numero di riferimento del mandato",
    "homeclub": "Club di casa",
    "credit": "Credito",
    "addFriendGroup": "Aggiungi un gruppo di amici",
    "addCredit": "Credito di ricarica",
    "amount": "Importo",
    "recharge": "Ricarica",
    "automaticRechargeBeyond": "Ricarica automatica se il credito è inferiore a questo importo",
    "paymenttype": "Opzione di pagamento",
    "rechargeBy": "Aumentare di importo",
    "amountToPay": "Importo da pagare",
    "freeSlots": "Slot gratuite",
    "addAdditionalFlightFriends": "Altri amici",
    "card": "La mia carta",
    "assignedCards": "Carte assegnate",
    "valid": "Validità",
    "noCardFound": "Nessuna carta trovata",
    "permissions": "Permessi",
    "status": "Stato",
    "locked": "Bloccato",
    "active": "Attivo",
    "cardHistory": "Storia della carta",
    "noHistory": "Nessun dato disponibile",
    "clubSelection": "Selezione del club",
    "bonuspoints": "Punti bonus",
    "creditoncard": "Credito sulla carta",
    "getBalls": "Richiesta di ottenere le palle",
    "getCheckIn": "Richiesta di check in",
    "getDoor": "Richiesta di",
    "pressButton": "Premi il pulsante d'argento sulla macchina per le palline",
    "ballmachineTimerRunning": "Premi il pulsante d'argento sulla macchina delle palle entro il prossimo",
    "checkInTimerRunning": "Premi il pulsante argentato sul CheckIn entro il prossimo",
    "doorTimerRunning": "Premi il pulsante argentato sulla porta entro i prossimi minuti",
    "bookingTimerRunning": "Premi il pulsante argentato sul lettore entro il prossimo",
    "seconds": "Secondi",
    "appButtonError": "Errore nella gestione della richiesta. Si prega di riprovare tra qualche secondo.",
    "r0000001": "Qualcosa è andato storto durante la creazione della transazione",
    "r0000002": "Hai creato un pagamento. Per favore, premi il pulsante argentato sul lettore per confermare il pagamento.",
    "r0000003": "Il lettore è utilizzato da altre persone. Si prega di attendere un momento e riprovare",
    "r0000004": "Questo numero DGV è già stato utilizzato",
    "r0000005": "Hardware non trovato",
    "frequency0": "",
    "frequency1": "mensile",
    "frequency2": "quadrimestrale",
    "frequency3": "semestrale",
    "frequency4": "annuale",
    "frequency5": "giornaliero",
    "packageAvailable": "disponibile",
    "generalPackage": "Pacchetti di cesti di palline",
    "personalPackage": "Pacchetti di cesti di palline personali",
    "noRPRs": "Nessun giro privato registrato trovato oggi",
    "RPRs": "Giri privati registrati",
    "RPR": "Giro privato registrato",
    "getBooking": "Richiesta di accensione",
    "news": "Notizie sul Golf Club",
    "imprint": "Impressum",
    "alreadyInFlight": " è già in flight",
    "acceptAgb": "Accetto i termini e le condizioni generali",
    "acceptDSGVO": "Accetto il regolamento sulla protezione dei dati",
    "agb": "Všeobecné podmínky",
    "datalegal": "Privacy policy",
    "marshalls": "Marshall",
    "send": "Inviare",
    "messageToOffice": "Messaggio alla segreteria",
    "messageSent": "Messaggio inviato",
    "statusSet": "Lo stato è stato impostato",
    "holes": "Fori",
    "tee": "Tee off",
    "personalScorecard": "Scheda di valutazione personale",
    "getScorecard": "Alla scheda di valutazione",
    "length": "Metri",
    "faq": "FAQs",
    "yes": "Sì",
    "no": "No",
    "checkIn": "Controllare",
    "checkInText": "I tempi di oggi devono essere impostati su presente?",
    "DSGVO": "Politica sulla privacy",
    "Agb": "Termini e condizioni generali",
    "noNews": "Nessuna notizia disponibile",
    "noPlayers": "Non ci sono giocatori nel gruppo",
    "functions": "Le esperienze digitali di golf elencate qui sotto sono fornite da questo golf club!",
    "wantToLogout": "Vorrei disconnettermi.",
    "canLogin": "Ora accedi con la password assegnata e questo numero di 10 cifre: ",
    "registerGuest": "Registrazione per gli ospiti",
    "nickname": "Nickname",
    "contactClub": "Per modificare i dati, contattare il club",
    "today": "Oggi",
    "hcpiInputHint": "Inserire HCPI™ come numero. Usa un punto come separatore per il punto decimale.",
    "noInternet": "Non tutti i risultati sono stati trasmessi. Si prega di controllare la connessione internet!",
    "closestClub": "Il club più vicino",
    "mobileScreen1": "Esperienze digitali di golf nel tuo golf club",
    "mobileScreen2": "Rangebooking",
    "mobileScreen3": "Pro e corsi",
    "mobileScreen4": "Gestire le carte",
    "deleteAccount": "Cancellare l'account",
    "wantToDeleteAccount": "Cancellare l'account?",
    "noWellness": "Nessun slot benessere trovato",
    "wellnesses": "Wellness",
    "wellnessHelp": "Help Wellness",
    "wellnessConfigs": "Wellness configuration",
    "makeWellnessBooking": "Prenota slot benessere",
    "homewellnesses": "Prenota slot benessere",
    "noTrackman": "Nessuno spazio trackman trovato",
    "trackmans": "Trackman",
    "TrackmanHelp": "Aiuto Trackman",
    "TrackmanConfigs": "Configurazioni Trackman",
    "makeTrackmanBooking": "Prenotazione dello spazio Trackman",
    "hometrackmans": "Prenotazione dello spazio Trackman",
    "noIndoors": "Non sono stati trovati spazi indoor",
    "indoors": "Indoor",
    "indoorHelp": "aiuto indoor",
    "indoorConfigs": "Configurazioni indoor",
    "makeIndoorBooking": "Prenota uno spazio indoor",
    "homeindoors": "Prenota uno spazio indoor",
    "countryCallingCode":"Codice paese",
    "getMobileCode":"Ottenere il codice",
    "sentMobileCode":"Il codice di verifica è stato inviato e scadrà tra 5 minuti.",
    "setNewEmail":"impostare una nuova e-mail",
    "setNewEmailDone":"L'e-mail è stata impostata con successo. Controllare la casella di posta elettronica per attivare l'account.",
    "mobileCondeSendOK":"Il codice è stato inviato con successo al vostro cellulare.",
    "mobileCondeSendError": "Si è verificato un errore durante l'invio del codice",
    "setEmailByMobileCodeOK":"L'e-mail è stata impostata con successo",
    "setEmailByMobileCodeError": "Si è verificato un errore durante l'impostazione dell'e-mail",
    "other":"Altro",
    "alllowSearch":"Posso essere trovato nella funzione di ricerca (lista amici, prenotazione dell'ora di inizio, torneo).",
    "privacyInfoscreen":"mostra Infoscreen",
    "wantToDeleteScoreCard": "Vuoi cancellare il punteggio?",
    "wantToCleanScoreCard": "Si desidera eliminare tutti i punteggi?",
    "createNewRound": "Iniziare un nuovo ciclo",
    "saved":"salvato",
    "date":"Data",
    "year":"Anno",
    "savedScoreCard":"Schede di valutazione salvate",
    "updateAppToUSe": "Aggiornare l'applicazione per continuare a utilizzarla",
    "appUpdating": "L'applicazione è in fase di aggiornamento, si prega di attendere un momento...",
    "aboutApp": "About App",
    "updateApp": "Update",
    "mobileNoZero": "Numero di cellulare SENZA lo 0 iniziale",
    "ballausgabe": "Prendi le palle",
    "cardnumber": "Numero di carta",
    "checkin": "Check-in",
    "dooropen": "Porta aperta",
    "turnon": "Accendi",
    "units": "Unità",
    "courseInfoToday": "Informazioni sul corso oggi",
    "digitalScorecard": "Tournaments Digital Scorecard",
    "digitalLivescoring": "Tornei Livescoring",
    "saveAndGo": "Save and Go",
    "saveScoreError": "Non sono stati inseriti tutti i punteggi",
    "courseStart": "Start",
    "courseEnd": "Last Unit",
    "refereeText": "Eseguire la chiamata dell'arbitro?",
    "newFlight": "Il flight è cambiato. Si prega di salvare di nuovo il punteggio!",
    "tutorial": "Tutorial",
    "tutorialTeetime": "Teetimes del libro",
    "tutorialTournaments": "Gestire i tornei",
    "tutorialPros": "Prenotare lezioni e corsi di coaching",
    "tutorialPersonale": "La mia scheda / Profilo / Notizie",
    "tutorialScoring": "Tornei con punteggio digitale",
    "tutorialUseReader": "Funzionamento del lettore di schede",
    "payLastschrift": "Pagamenti con addebito diretto",
    "payLastschriftHint": "L'autorizzazione all'addebito diretto viene depositata nel club. L'importo dovuto sarà addebitato sul conto depositato",
    "addCreditLastschrift": "Ricarica del credito",
    "allow4USearch": "Voglio essere trovato nella ricerca delle persone",
    "newsperson": "My News",
    "sunsetInfoToday": "Tramonto oggi",
    "sunsetInfo": "Tramonto",
    "sunsetBooking": "L'teetime potrebbe durare fino al tramonto",
    "friendsHelp": "Aiuto amici",
    "friendsAccepted": "Richiesta di amicizia accettata",
    "friendsNotYetAcceppted": "Richiesta di amicizia non ancora accettata",
    "friendsHelpDeclined": "Richiesta di amicizia respinta/rifiutata",
    "friendsDelete": "Elimina amico",
    "AmiciFemmina": "Femmina",
    "amiciMaschio": "Maschio",
    "friendsAdd": "Aggiungi gruppo di amici o amico",
    "friendsOpenDetail": "Dettagli aperti",
    "friendsCloseDetail": "Dettagli di chiusura",
    "RoundFinished": "Round finito",
    "allowSingleEntryTeamError": "L'ingresso singolo nel gioco di squadra non è consentito!",
    "clearScores": "Cancella tutti i punteggi",
    "time": "ora del giorno",
    "useLandscape": "Utilizzate la modalità orizzontale sul vostro dispositivo mobile!",
    "general": "Generale",
    "bills": "Fatture",
    "teetime": "Teetime booking",
    "deleteNews": "Messaggio di cancellazione",
    "deleteThisNews": "Cancellare questo messaggio?",
    "aboname": "Nome",
    "aboInfo": "Abbonamenti",
    "aboHistory": "Storia dell'abbonamento",
    "BookAndPay": "Prenota e paga",
    "noPaymentMethod": "Per ricaricare il credito, depositare l'addebito diretto nel club se necessario",
    "rounds": "Giri",
    "digitalScorecardLegend1": "Trasmette e salva il punteggio",
    "digitalScorecardLegend2": "Nessun punteggio inserito o modificato",
    "digitalScorecardLegend3": "Il punteggio è stato modificato ma non ancora trasmesso e salvato",
    "digitalScorecardLegend4": "Il punteggio è stato trasmesso e salvato con successo",
    "digitalScorecardLegend1": "Geeft de score door en slaat deze op",
    "digitalScorecardLegend2": "Geen score ingevoerd of gewijzigd",
    "digitalScorecardLegend3": "Score is gewijzigd maar nog niet verzonden en opgeslagen",
    "digitalScorecardLegend4": "Score is succesvol overgedragen en opgeslagen",
    "enterEmail": "Inserire l'e-mail memorizzata e richiedere nuovamente l'e-mail",
    "infoScreenTitle": "Display Infoscreen",
    "infoScreenMessage": "Selezionare Sì per essere visualizzati sul terminale esterno (Infoscreen). Questa impostazione può essere modificata nuovamente in Profilo!",
    "addRPR": "Registra il giro privato",
    "syncHCPI": "Controlla l'HCPI™ corrente...",
    "noRPR": "L'interrogazione dell'HCPI™ corrente non è possibile. Non è possibile registrare alcun giro RPR.",
    "searchMarker": "Ricerca del contatore",
    "addRPRDebit": "Creare un giro RP e pagare con addebito diretto",
    "addRPRRound": "Crea un giro RP",
    "saveRPRRound": "Il giro RP è stato salvato e trasferito con successo",
    "saveRPRError" : "Si è verificato un errore durante il salvataggio del giro RP",
    "notAllScoresSaved" : "Firma visibile quando tutti i punteggi sono salvati",
    "scorecardSaved" : "La scorecard è stata salvata con successo",
    "GuestsQRCode" : "QRCodes per la scorecard digitale",
    "addLock" : "Aggiungi blocco",
    "addLockSuccess" : "Blocco aggiunto con successo",
    "setGolfChainHeader" : "Attenzione! Attivare l'iscrizione alla catena del golf",
    "setGolfChainText" : "Vuoi davvero attivare l'iscrizione alla catena di golf?",
    "noBlanks" : "Senza spazi o caratteri speciali",
    "tendigits" : "10 cifre",
    "eightdigits" : "8 cifre",
    "ninedigits" : "9 cifre",
    "golfChain" : "Sono un membro di Golf Chain",
    "ask9holes" : "Giocare a 18 o 9 buche?",
    "ask9holesText" : "Indica quante buche intendi giocare!",
    "9holes" : "9 buche",
    "18holes" : "18 buche",

    "de": "Tedesco",
    "en": "Inglese",
    "it": "Italiano",
    "fr": "Francese",
    "cz": "Ceco",
    "cn": "Cinese",
    "nl": "Olandese",
    "es": "Spagnola",


    "ls0001": "Le vostre coordinate bancarie non sono complete, contattate il vostro club di golf",
    "ls0002": "Il pagamento con addebito diretto è stato disattivato dal golf club",
    "dfdae65b": "Nessun utente trovato con l'indirizzo e-mail indicato",
    "71b72952": "Password sbagliata",
    "71b72951": "Indirizzo e-mail mancante. Si prega di contattare il club!",
    "71b72953": "Errore del gettone",
    "71b72954": "Token scaduto",
    "71b72955": "Password non valida",
    "df6b0128": "Un giocatore del gruppo non può prenotare",
    "df6b0129": "Non connesso",
    "df6b0130": "Utente non trovato",
    "df6b0131": "Teetime è bloccato",
    "df6b0132": "Teetime non trovato",
    "df6b0133": "Prenotazione nel passato",
    "df6b0134": "La prenotazione non ha configurazione",
    "df6b0135": "La prenotazione non può essere cancellata",
    "df6b0136": "Non ci sono slot disponibili",
    "df6b0137": "Corso non trovato",
    "df6b0138": "Registrazione non consentita",
    "df6b0139": "Disiscrizione non consentita",
    "df6b0140": "Calendario non trovato",
    "df6b0141": "Tempo di fine raggiunto",
    "df6b0142": "Prenotazione precedente al tempo raggiunto",
    "df6b0143": "Slot non disponibile",
    "df6b0144": "Il tempo è nel passato",
    "df6b0145": "Giorni massimi raggiunti",
    "df6b0146": "Periodo di cancellazione raggiunto",
    "df6b0147": "Torneo non trovato",
    "df6b0148": "Pro non trovato",
    "df6b0149": "Gamma non trovata",
    "df6b0150": "Teetime ha pagato online",
    "df6b0151": "Gamma pagata online",
    "df6b0152": "Pro pagato online",
    "df6b0153": "Carrello non disponibile",
    "df6b0154": "Utente bloccato per 4U",
    "19e28470": "L'utente esiste già",
    "7e341661": "Errore interno",
    "7e341662": "Errore di accesso",
    "bfe57d09": "Nessun utente con questo login trovato",
    "bfe57d10": "Nessuna email per l'utente",
    "b4d05e56": "Qualcosa è andato storto durante il caricamento del file",
    "b4d05e57": "Qualcosa è andato storto durante la cancellazione del file",
    "b4d05e58": "Qualcosa è andato storto durante il salvataggio dei dati",
    "b4d05e59": "Qualcosa è andato storto durante l'acquisizione dei dati",
    "b4d05e60": "Il numero di idcard è già registrato. Si prega di utilizzare questo numero per il login",
    "b4d05e61": "Il paese/club selezionato non è stato trovato",
    "b4d05e62": "Una persona con i dati forniti esiste già nel sistema. Accedi con questo id utente: #",
    "b4d05e63": "La persona non è stata trovata. Si prega di controllare i dati forniti con quelli presenti sulla carta d'identità (nome, cognome)"
  }
}