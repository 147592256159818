<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-button fill="none" @click="generatePDF">
            <ion-icon color="primary" :ios="printOutline" :md="printOutline" ></ion-icon>
          </ion-button>      
        <ion-title>{{ $t('helpTournamentML') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-card>
      <ion-card-header>
        <ion-card-title :style="isPlatform('mobile') ? 'font-size: 18px !important;': 'font-size: 20px !important;'" v-if="selectedTournament">
          {{ selectedTournament.name }} {{ dayjs(selectedTournament.begin).format('DD.MM.YYYY HH:mm') }}
        </ion-card-title>
      </ion-card-header>
      <ion-list lines="full" class="ion-no-margin">
        <ion-item v-for="(item, index) in tournamentPlayers" :key="index" :value="item">
            <ion-label class="ion-text-wrap">
              <ion-icon  :color="item.sex_sport" :md="(item.sex_sport == 'female' ? womanSharp : (item.sex_sport == 'male' ? manSharp: personOutline))" :ios="(item.sex_sport == 'female' ? womanSharp : (item.sex_sport == 'male' ? manSharp: personOutline))" style="zoom: 1.2; margin-right: 5px;"></ion-icon>
              {{ item.lastName }} {{ item.firstName }} {{item.hcp}}
            </ion-label>

            <ion-label  class="ion-text-wrap" v-for="(tp, ti) in item.teamPlayers" :key="ti" :value="tp">
              <ion-icon  :color="tp.sex_sport" :md="(tp.sex_sport == 'female' ? womanSharp : (tp.sex_sport == 'male' ? manSharp: personOutline))" :ios="(tp.sex_sport == 'female' ? womanSharp : (tp.sex_sport == 'male' ? manSharp: personOutline))" style="zoom: 1.2; margin-right: 5px;"></ion-icon>
              {{  tp.lastName }} {{ tp.firstName }} {{ tp.hcp }}
            </ion-label>

            <ion-avatar @contextmenu.prevent="" :class="imgClass" @click="changeImg()" v-if="item.avatarLink != '' && item.showAvatar">
              <img :src="item.avatarLink">
            </ion-avatar>
        </ion-item>
      </ion-list>
    </ion-card>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, toastController,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle, isPlatform,
IonAvatar } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, manSharp, womanSharp, personOutline, printOutline } from 'ionicons/icons';
import { useQuery, useResult } from '@vue/apollo-composable'
import getEntryListByTournamentIdQueryFile from '../../graphql/getEntryListByTournamentId.query.gql'
import * as dayjs from 'dayjs'; 
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { File } from '@awesome-cordova-plugins/file';
import { FileOpener } from '@awesome-cordova-plugins/file-opener';

export default defineComponent({
  name: 'ShowEntryListDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonAvatar,
              },
  props: ['propsData'],
  setup() {
    let selectedTournament = ref(null)
    const imgClass = ref('playerImg')

    const { result: getEntryListByTournamentIdResult } = useQuery(getEntryListByTournamentIdQueryFile, () => ({ id: (selectedTournament.value ? parseInt(selectedTournament.value.id) : 0) }), { fetchPolicy: 'no-cache' })
    let tournamentPlayers = useResult(getEntryListByTournamentIdResult, null, data => data.getEntryListByTournamentId.tournamentPlayers)
    return { dayjs, closeOutline, selectedTournament, tournamentPlayers, manSharp, womanSharp, personOutline, isPlatform, imgClass, printOutline
    }
  },
  mounted() {
    this.selectedTournament = this.propsData.selectedTournament

  },
  data() {
    var listDetail = []
    var listHeader = []
    return {  
      listDetail, listHeader
    }
  },
  methods: {
    generatePDF(){   
      this.listDetail = []

      this.tournamentPlayers.forEach(item => {
        this.listDetail.push([item.lastName + ' ' + item.firstName + ' ' + item.hcp])
      }); 
      this.listHeader = []
      this.listHeader.push({ text: this.$t('name') })
/     this.listDetail.unshift(this.listHeader)

      const docDefinition = {
        footer: function(currentPage, pageCount, pageSize) {
            return [
              { text: dayjs().format('DD.MM.YYYY HH:mm') + ' ' +currentPage+'/'+pageCount, alignment: 'right', fontSize: 10, margin: [0, 0, 20, 0] },
            ]
          },
        content: [
            {text: this.$t('helpTournamentML'), fontSize: 14, bold: true},
            '\n',
            {text: this.selectedTournament.name + ' ' + dayjs(this.selectedTournament.begin).format('DD.MM.YYYY HH:mm'), fontSize: 12, bold: true},
            '\n',  
            {
                style: 'tableItems',
                table: {
                    headerRows: 1,
                    body: this.listDetail
                },
                layout: 'headerLineOnly'
            },
        ],
        styles: {
            footer: {
                fontSize: 8,
            },
            tableItems: {
                fontSize: 8
            }
        },
        defaultStyle: {
            columnGap: 20
        }        
      }

      const pdfDocGenerator = pdfMake.createPdf(docDefinition)
      if (isPlatform('capacitor'))
        pdfDocGenerator.getBuffer((buffer) => {
          var blob = new Blob([buffer], { type: 'application/pdf' });
          File.writeFile(File.dataDirectory, 'ml.pdf', blob, { replace: true }).then(fileEntry => {
          // Open the PDf with the correct OS tools
          FileOpener.open(File.dataDirectory + 'ml.pdf', 'application/pdf');
          })
        })
      else
        pdfDocGenerator.open()
    },
    changeImg() {
      if (this.imgClass == 'playerImg')
        this.imgClass = 'playerImgBig'
      else
        this.imgClass = 'playerImg'
    },
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>

<style scoped>

.playerImg {
  width: 40px; 
  height: 40px;
}

.playerImgBig {
  width: 120px; 
  height:120px
}

</style>